<template>
<div class="BasicInfo">

</div>
</template>
<script>
export default {
name:'AvatarInfo',
data(){
return {
}
},
created(){
},
}
</script>
<style lang='scss' scoped>
</style>