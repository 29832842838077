<template>
  <div class="memberInfo">
    <my-nav>
      <my-nav-item
        v-for="(item, index) in nav"
        :key="item.name"
        :name="item.name"
        :needLine="true"
        :active="index == choose"
        @click.native="changeClass(index)"
      >
      </my-nav-item>
    </my-nav>

    <div class="component" v-if="memberData.userinfo">
      <keep-alive>
        <component v-bind:is="component[choose]" :memberData="memberData"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import { MyNav, MyNavItem } from "common/mynav";
import { AvatarInfo, MoreInfo, BasicInfo } from "./memberInfoChild";
import User from "@/model/User.js";
export default {
  name: "MemberInfo",
  data() {
    return {
      nav: [
        { name: this.$t('Goods.Pages.BasicInfo') },
        { name:this.$t('Goods.Pages.Profile') },
        { name: this.$t('User.Orders.Crumbs.MyInfo')},
      ],
      choose: 0,
      component: ["BasicInfo", "AvatarInfo", "MoreInfo"],

                //会员信息
      memberData: {},
    };
  },
  created() {
        this.getMemberData();
  },
  methods: {
    changeClass(index) {
      this.choose = index;
    },

    //获取个人信息
     //请求会员信息数据
    getMemberData() {
      User.Info.Detail({
        data: {
          notredirect: "undefined",
        },
        succ: (res) => {
          console.log(res);
          this.memberData = res;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },
    
  },
  components: { MyNav, MyNavItem, AvatarInfo, MoreInfo, BasicInfo },
};
</script>
<style lang='scss' scoped>
.memberInfo {
  background-color: white;
  padding: 1rem;
  .component{
      margin-top:0.75rem;
  }
}
</style>