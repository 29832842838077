<template>
  <div class="BasicInfo">
    <div class="list">
      <div class="item">
        <div class="name">{{ $t("Passport.Email") }}</div>
        <div class="right">
          <div class="cont">566644@qq.com</div>
          <div class="btn" @click="change('email')">
            {{ $t("Member.Common.ModifyEmail") }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="name">{{ $t("Passport.Mobile") }}</div>
        <div class="right">
          <div class="cont">566644@qq.com</div>
          <div class="btn" @click="change('mobile')">
            {{ $t("Member.Common.ModifyPhone") }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="name">{{ $t("Passport.Password") }}</div>
        <div class="right">
          <div class="cont"></div>
          <div class="btn" @click="change('changePassword')">
            {{ $t("User.Account.Pages.ChangePwd") }}
          </div>
        </div>
      </div>
      <!-- <div class="item">
        <div class="name">{{ $t("Passport.Pages.FindPwd") }}</div>
        <div class="right">
          <div class="cont"></div>
          <div class="btn" @click="change('findPassword')">
            {{ $t("Passport.Pages.FindPwd") }}
          </div>
        </div>
      </div> -->
    </div>

    <!-- 弹框 -->
    <pop-up :isshow="isshowDialog" width="35vw">
      <div class="pop">
        <div class="header">
          <span>{{ popname }}</span>
          <i class="iconfont icon-guanbi close" @click="close"></i>
        </div>

        <!-- 修改密码 -->
        <div class="content_f" v-if="choose == 'changePassword'">
          <div class="group" v-for="item in state[choose]" :key="item.name">
            <div class="changePassword">
              <span class="name"><strong>*</strong>{{ item.name_zh }}：</span>
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="item.val"
                class="input"
                type="password"
              ></has-icon-input>
            </div>
          </div>
        </div>

        <!-- 修改邮箱 -->
        <div class="content_f changeEmail" v-if="choose == 'email'">
          <div class="group" v-if="emailStep == 0">
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong>{{ $t("Member.Common.OldEmail") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].email.val"
              ></has-icon-input>
            </div>
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong
                >{{ $t("Passport.Pages.FindPwdCaptcha") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].captcha.val"
                class="short"
              ></has-icon-input>
              <div class="captcha">
                <!-- <img :src="captcha" alt="" @click="getcaptcha" /> -->
                {{ $t("Passport.Pages.SendVerifyToEmail") }}
              </div>
            </div>
            <div class="emailBtn" @click="next">
              <span>{{ $t("Goods.Pages.Continue") }}</span>
            </div>
          </div>
          <div class="group" v-if="emailStep == 1">
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong>{{ $t("Member.Common.NewEmail") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].email.val"
              ></has-icon-input>
            </div>
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong
                >{{ $t("Passport.Pages.FindPwdCaptcha") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].captcha.val"
                class="short"
              ></has-icon-input>
              <div class="captcha">
                {{ $t("Passport.Pages.SendVerifyToEmail") }}
              </div>
            </div>
            <div class="emailBtn" @click="saveOrEdit">
              <span>{{ $t("Member.Common.Submit") }}</span>
            </div>
          </div>
        </div>

        <!-- 修改手机号 -->
        <div class="content_f changeEmail" v-if="choose == 'mobile'">
          <div class="group" v-if="mobileStep == 0">
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong>{{ $t("Member.Common.NewEmail") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].mobile.val"
              ></has-icon-input>
            </div>
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong
                >{{ $t("Passport.Pages.FindPwdCaptcha") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].captcha.val"
                class="short"
              ></has-icon-input>
              <div class="captcha">
                <!-- <img :src="captcha" alt="" @click="getcaptcha" /> -->
                {{ $t("Passport.Pages.SendVerifyToEmail") }}
              </div>
            </div>
            <div class="emailBtn" @click="next">
              <span>{{ $t("Goods.Pages.Continue") }}</span>
            </div>
          </div>
          <div class="group" v-if="mobileStep == 1">
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong>{{ $t("Member.Common.NewMobile") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].mobile.val"
              ></has-icon-input>
            </div>
            <div class="emailBox">
              <span class="name"
                ><strong>*</strong
                >{{ $t("Passport.Pages.FindPwdCaptcha") }}：</span
              >
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].captcha.val"
                class="short"
              ></has-icon-input>
              <div class="captcha">
                {{ $t("Passport.Pages.SendVerifyToEmail") }}
              </div>
            </div>
            <div class="emailBtn" @click="saveOrEdit">
              <span>{{ $t("Member.Common.Submit") }}</span>
            </div>
          </div>
        </div>

        <!-- 找回密码 -->
        <div class="content_f changeEmail" v-if="choose == 'findPassword'">
          <div class="group" v-if="findPasswordStep == 0">
            <div class="emailBox">
              <span class="name"><strong>*</strong>手机号：</span>
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].type.val"
              ></has-icon-input>
            </div>
            <div class="emailBox">
              <span class="name"><strong>*</strong>验证码：</span>
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].captcha.val"
                class="short"
              ></has-icon-input>
              <div class="captcha">
                <!-- <img :src="captcha" alt="" @click="getcaptcha" /> -->
                发送验证码
              </div>
            </div>
            <div class="emailBtn" @click="next"><span>下一步</span></div>
          </div>
          <div class="group" v-if="findPasswordStep == 1">
            <div class="emailBox">
              <span class="name"><strong>*</strong>新密码：</span>
              <has-icon-input
                :height="30"
                backgroundColor="#f6f6f6"
                v-model="state[choose].newpassword.val"
              ></has-icon-input>
            </div>
            <div class="emailBtn" @click="saveOrEdit"><span>提交</span></div>
          </div>
        </div>

        <div class="btn" v-if="choose == 'changePassword'">
          <span class="userInfo" @click="saveOrEdit">{{$t('Common.Button.Save')}}</span>
        </div>

        
      </div>
    </pop-up>
  </div>
</template>
<script>
import PopUp from "common/popup/PopUp";
import HasIconInput from "common/input/HasIconInput";
import User from "model/User.js";
export default {
  name: "MoreInfo",
  data() {
    return {
      //是否显示弹框
      isshowDialog: false,

      //弹框的文字
      popname: "",

      //当前选择的修改项
      choose: "",

      copyState: {},

      state: {
        email: {
          captcha: { name: "captcha", name_zh: this.$t('Passport.Pages.FindPwdCaptcha'), val: "" },
          email: { name: "email", name_zh: this.$t('Member.Common.NewEmail'), val: "" },
        },
        mobile: {
          mobile: { name: "mobile", name_zh: this.$t('Member.Common.NewMobile'), val: "" },
          captcha: { name: "captcha", name_zh: this.$t('Passport.Pages.FindPwdCaptcha'), val: "" },
        },
        changePassword: {
          old_password: { name: "old_password", name_zh:this.$t('Member.Common.OldPassword'), val: "" },
          new_password: { name: "new_password", name_zh: this.$t('Passport.Pages.NewPassword'), val: "" },
          repeat_password: {
            name: "repeat_password",
            name_zh: this.$t('Passport.Placeholder.ConfirmPassword'),
            val: "",
          },
        },
        findPassword: {
          type: { name: "type", name_zh: this.$t('Passport.Mobile'), val: "", value: "mobile" },
          captcha: { name: "captcha", name_zh: this.$t('Passport.Pages.FindPwdCaptcha'), val: "" },
          newpassword: { name: "newpassword", name_zh: this.$t('Passport.Pages.NewPassword'), val: "" },
        },
      },

      // 邮箱的步骤
      emailStep: 0,

      // 手机的步骤
      mobileStep: 0,

      //   找回密码步骤
      findPasswordStep: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      //   拷贝一份state
      this.copyState = JSON.parse(JSON.stringify(this.state));
    },
    //关闭弹框
    close() {
      this.isshowDialog = false;
      this.resetState();
    },

    //重置state
    resetState(saveChOrName) {
      this.state = JSON.parse(JSON.stringify(this.copyState));
      if (!saveChOrName) {
        this.choose = "";
        this.popname = "";
        this.emailStep = 0;
        this.mobileStep = 0;
        this.findPasswordStep = 0;
      }
    },

    next() {
      if (this.isEmpty(this.state[this.choose], "newpassword")) {
        this.$message("error",this.$t('Passport.Validation.isEmpty'));
        return;
      }

      //网络请求

      // 如果验证成功重置state，并进入下一节点
      this.emailStep++;
      this.mobileStep++;
      this.findPasswordStep++;

      // 如果是找回密码则不需要清除
      if (this.choose == "findPassword") {
        return;
      } else {
        this.resetState(true);
      }
    },

    change(type) {
      this.choose = type;
      this.isshowDialog = true;
      if (type == "email") {
        this.popname = this.$t('Member.Common.ModifyEmail');
      } else if (type == "mobile") {
        this.popname = this.$t('Member.Common.ModifyPhone');
      } else if (type == "changePassword") {
        this.popname = this.$t('User.Account.Pages.ChangePwd');
      } else if (type == "findPassword") {
        this.popname =this.$t('Passport.Pages.FindPwd');
      }
    },

    //判断是否有内容为空
    isEmpty(ob, nofind) {
      for (let item in ob) {
        if (item == nofind) {
          continue;
        }
        if (ob[item].val == "") {
          return true;
        }
      }
      return false;
    },

    //保存信息
    saveOrEdit() {
      //判断当前输入框是否有内容为空
      let ob = this.state[this.choose];

      if (this.isEmpty(ob)) {
        this.$message("error", this.$t('Passport.Validation.isEmpty'));
        return;
      }



      //发送的数据
      let sendData = {};
      for (let item in ob) {
        sendData[item] = ob[item].val;
      }

      //修改密码
      if (this.choose == "changePassword") {
        User.Info.ChangePassword({
          data: sendData,
          succ: (res, all) => {
            this.$message("success", all.msg);
            this.close();
            setTimeout(() => {
              location.reload();
            }, 300);
          },
          fail: (res, all) => {
            this.$message("error", all.msg);
          },
        });
      }
    },
  },
  components: {
    PopUp,
    HasIconInput,
  },
};
</script>
<style lang='scss' scoped>

.BasicInfo {
  padding-bottom: 1.5rem;
  .list {
    padding-left: 1rem;
    font-size: 0.75rem;
    .item {
      color: var(--text-color-title);
      border-bottom: 1px solid var(--border-color-default);
      display: flex;
      padding: 1.5rem 0 1.5rem 0.5rem;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .right {
        display: flex;
        align-items: center;
        .btn {
          margin-left: 1.5rem;
          cursor: pointer;
          color: #1989fa;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.pop {
  // width: 100%;
  .header {
    // width: 25rem;
    width: 100%;
    box-sizing: border-box;
    background-color: #f3f3f3;
    display: flex;
    justify-content: space-between;
    color: var(--text-color-default);
    font-size: 0.875rem;
    padding: 0.3rem 0.2rem;
    align-items: center;
    i {
      cursor: pointer;
    }
  }

  .content_f {
    padding: 2.5rem;
    background-color: white;
    .group {
      .emailBox,
      .changePassword {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .name {
          margin-right: 0.5rem;
          display: block;
          width: 8rem;
          text-align: right;
          font-size: 0.75rem;
          color: var(--text-color-default);
          strong {
            color: var(--red-color);
            margin-right: 3px;
          }
        }
        .captcha {
          height: 30px;
          background-color: var(--red-color);
          color: white;
          width: 10rem;
          text-align: center;
          line-height: 30px;
          font-size: 0.75rem;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .input {
          width: 24rem;
        }
        //当该输入框对应的是验证码时，此输入框应当小一些
        .short {
          width: 14rem;
        }
      }
    }
  }

  @mixin base_btn {
    background-color: var(--red-color);
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }

  .emailBtn {
        margin-top:2rem;
    display: flex;
    justify-content: flex-end;
    span {
      @include base_btn;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2.5rem;
    padding-right: 2.5rem;
    span {
      @include base_btn;
    }
  }
}
</style>