<template>
  <div class="navItem">
    <span :class="{ active: active }"
      >{{ name }}
      <div v-if="needLine && active" class="line"></div>
    </span>
  </div>
</template>
<script>
export default {
  name: "navItem",
  data() {
    return {};
  },
  props: {
    name: {
      type: String,
    },
    active: {
      type: Boolean,
    },
    needLine: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  created() {},
};
</script>
<style lang='scss' scoped>

.navItem {
  cursor: pointer;
  span {
    display: inline-block;
    padding: 0.875rem 1.2rem;
    font-size: 0.75rem;
    color: #424245;
    position: relative;
    .line {
        position: absolute;
      width: calc(100% - 1rem - 1rem);
      height: 1px;
      background-color: #dc3545;
      bottom: 5px;
      left: 1rem;
    }
  }

  .active {
    color: #dc3545;
    background-color: white;
  }
}
</style>