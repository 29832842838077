<template>
  <div class="nav">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "MyNav",
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.nav{
    display: flex;
}
</style>