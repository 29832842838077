<template>
  <div class="BasicInfo">
    <div class="left">
      <div class="item">
        <div class="name">{{$t('App.Rcom.Pages.UserName')}}:</div>
        <div class="val needDeep">{{memberData.userinfo.username}}</div>
      </div>
      <div class="item">
        <div class="name">{{$t('User.Account.Pages.NickName')}}:</div>
        <div class="val">
          <input type="text" v-model="nickname" class="needDeep nickname" />
        </div>
      </div>
      <div class="item">
        <div class="name">{{$t('User.Address.Gender')}}:</div>
        <div class="val">
          <input type="radio" v-model="sex" :value="0" id="man" /><label
            class="sex"
            for="man"
            >{{$t('User.Address.Man')}}</label
          >
          <input type="radio" v-model="sex" :value="1" id="woman" /><label
            class="sex"
            for="woman"
            >{{$t('User.Address.Woman')}}</label
          >
          <input type="radio" v-model="sex" :value="-1" id="secrecy" /><label
            class="sex"
            for="secrecy"
            >{{$t('User.Address.keepSecret')}}</label
          >
        </div>
      </div>
      <div class="item">
        <div class="name">{{$t('User.Address.Birthday')}}:</div>
        <div class="val">
          <select v-model="year.choose" class="birthday">
            <option disabled value="">{{$t('Member.Common.Selection')}}</option>
            <option v-for="item in year.arr" :key="item">{{ item }}</option>
          </select>
          <span class="title">{{$t('Member.Common.Year')}}</span>
          <select v-model="month.choose" class="birthday">
            <option disabled value="">{{$t('Member.Common.Selection')}}</option>
            <option v-for="item in month.arr" :key="item">{{ item }}</option>
          </select>
          <span class="title">{{$t('Member.Common.Month')}}</span>
          <select v-model="day.choose" class="birthday">
            <option disabled value="">{{$t('Member.Common.Selection')}}</option>
            <option v-for="item in day.arr" :key="item">{{ item }}</option>
          </select>
          <span class="title">{{$t('Member.Common.Day')}}</span>
        </div>
      </div>

      <div class="item">
        <div class="name"></div>
        <div class="val">
          <div class="btn" @click="changeInfo">
            <span>{{$t('Member.Common.Submit')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="userAvatar">
        <div class="img">
          <img v-lazy="avatar" alt="" />
        </div>

        <div class="info">
          <div class="name itemInfo font_hide">{{$t('App.Rcom.Pages.UserName')}}：{{memberData.userinfo.username}}</div>
          <div class="point itemInfo">{{$t('Cart.Checkout.Pages.Point')}}：<span class="num">{{memberData.userinfo.point}}</span></div>
          <div class="balance itemInfo">
            {{$t('Member.Common.Balance')}}：<span class="num">{{memberData.userinfo.balance_text}}</span>
          </div>
          <div class="type itemInfo">{{$t('Member.Common.MemberType')}}：{{memberData.userinfo.group_name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/utils/config.js'
import User from "@/model/User.js";
export default {
  name: "BasicInfo",
  data() {
    return {
      year: {
        choose: "",
        arr: [],
      },
      month: {
        choose: "",
        arr: [],
      },
      day: {
        choose: "",
        arr: [],
      },

      nickname: this.memberData.userinfo.nickname,
      sex: -1,
    };
  },
  computed:{
    avatar(){
      return config.config.SERVER_PRODUCTION.replace('/api','')+this.memberData.userinfo.avatar
    }
  },
  watch: {
    "year.choose"() {
      this.createDay();
    },
    "month.choose"() {
      this.createDay();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.createYear();
      this.createMonth();
    },

    //   创建年份
    createYear() {
      let total = 100;
      let year = new Date().getFullYear();
      let yearArr = [];
      for (let i = 0; i < total; i++) {
        yearArr.push(year);
        year--;
      }
      this.year.arr = yearArr;
    },

    //创建月份
    createMonth() {
      let monthArr = [];
      for (let i = 0; i < 12; i++) {
        monthArr.push(i + 1);
      }
      this.month.arr = monthArr;
    },

    //创建天数
    createDay() {
      if (this.year.choose == "" || this.month.choose == "") {
        return;
      }
      this.day.choose = "";
      //判断当前年份是否为润年
      let isleapyear = this.year.choose % 4 == 0;
      let day;
      switch (this.month.choose*1) {
        case 1:
          day = 31;
          break;
        case 2:
          (() => {
            if (isleapyear) {
              day = 29;
            } else {
              day = 28;
            }
          })();
          break;
        case 3:
          day = 31;
          break;
        case 4:
          day = 30;
          break;
        case 5:
          day = 31;
          break;
        case 6:
          day = 30;
          break;
        case 7:
          day = 31;
          break;
        case 8:
          day = 31;
          break;
        case 9:
          day = 30;
          break;
        case 10:
          day = 31;
          break;
        case 11:
          day = 30;
          break;
        case 12:
          day = 31;
          break;
      }
      let arr = [];
      for (let i = 0; i < day; i++) {
        arr.push(i + 1);
      }
      this.day.arr = arr;
    },


    changeInfo(){


    }
  },
  props: {
    memberData:{
      type:Object
    }
  },
};
</script>
<style lang='scss' scoped>

.BasicInfo {
  display: flex;
  .left {
    width: 60%;
    box-sizing: border-box;
    .item {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      color: var(--text-color-default);
      margin-bottom: 1.5rem;
      .name {
        width: 15%;
        text-align: right;
        padding-right: 0.5rem;
        box-sizing: border-box;
      }
      .needDeep {
        color: var(--text-color-title);
      }

      .val {
        display: flex;
        align-items: center;
        .nickname {
          padding: 0.3rem 0.3rem;
        }

        .sex {
          margin: 0 0.5rem 0 0.1rem;
        }

        .birthday {
          padding: 0.2rem 0.2rem;
        }

        .title {
          margin: 0 0.4rem;
        }

        .btn {
          width: 4rem;
          height: 1.875rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--red-color);
          color: white;
          cursor: pointer;
          border-radius: 3px;
          &:hover {
            opacity: 0.8;
          }
        }
        width: 85%;
      }
    }
  }

  .right {
    width: 40%;
    box-sizing: border-box;
    .userAvatar {
      max-width: 18.875rem;
      font-size: 0.75rem;
      color: var(--text-color-default);
      background-color: #f9f9f9;
      padding: 1rem;
      box-sizing: border-box;
      border: 1px solid var(--border-color-default);
      display: flex;
      align-items: center;
      .img {
        border-radius: 50%;
        overflow: hidden;
        width: 30%;
        padding-bottom:30%;
        position: relative;
        max-width: 6.5rem;
        img {
          position: absolute;
          z-index: 2;
          height: 100%;
          vertical-align: bottom;
          width: 100%;
        }
      }

      .info {
        width: 70%;
        box-sizing: border-box;
        padding-left: 0.5rem;
        max-width: 10.625rem;
        .itemInfo {
          margin-bottom: 0.2rem;
        }
        .name {
          color: var(--text-color-title);
        }

        .point,
        .balance {
          .num {
            color: #005ea7;
          }
        }
      }
    }
  }
}
</style>